import React, { useEffect, useState } from "react";
import ReactGA from "react-ga";
import DangerousHTML from "dangerous-html/react";
import { Helmet } from "react-helmet";
import { useMutation, useQueryClient } from "react-query";
import Tippy from "@tippyjs/react";
import "tippy.js/dist/tippy.css";
import Navbar from "../components/navbar";
import Card from "../components/card";
import "./home.css";
import { Link } from "react-router-dom";
import { getDownloadUrl } from "../helpers/helper";

const Home = (props) => {
  const [buttonState, setButtonState] = useState("idle");

  const handleDownloadClick = async () => {
    if (buttonState === "idle") {
      setButtonState("downloading");
      const downloadUrl =
        "https://drive.google.com/file/d/1kZYzCYw1CqyByTF28b8OIOKAcQjyNLhF/view?usp=share_link";
      window.open(downloadUrl, "_blank");
      ReactGA.event({
        category: "Button Click",
        action: "Download Main APK",
      });
    }
  };

  const scrollToDownload = () => {
    const downloadElement = document.getElementById("download");
    if (downloadElement) {
      downloadElement.scrollIntoView({ behavior: "smooth" });
    }
  };

  const buttonText = "Download for Android";

  const handleEmailClick = () => {
    window.location.href = "mailto:lifesavi@gmail.com";
  };

  const handleTelegramClick = () => {
    window.location.href = "tg://resolve?domain=savihealer";
  };

  return (
    <div className="home-container">
      <Helmet>
        <title>App Bundle Installer</title>
        <meta property="og:title" content="Planical modern template" />
      </Helmet>
      <Navbar
        rootClassName="navbar-root-class-name"
        showMenuItems="true"
      ></Navbar>
      <section className="home-section">
        <div className="home-hero">
          <div className="home-content">
            <main className="home-main">
              <header className="home-header">
                <h1 className="home-heading gradient-text">
                  One-Stop Solution for Converting and Installing All App
                  Formats
                </h1>
                <span className="home-caption">
                  Convert and Install Android App Bundles (AAB) to APKs with
                  Ease
                </span>
              </header>
              <div className="home-buttons">
                <div
                  className="home-get-started button primary"
                  onClick={scrollToDownload}
                >
                  <span className="home-text">Download Now</span>
                </div>
              </div>
            </main>
            <div className="home-highlight">
              <div className="home-avatars">
                <img
                  alt="image"
                  src="https://images.unsplash.com/photo-1552234994-66ba234fd567?ixid=Mnw5MTMyMXwwfDF8c2VhcmNofDN8fHBvdHJhaXR8ZW58MHx8fHwxNjY3MjQ0ODcx&amp;ixlib=rb-4.0.3&amp;w=200"
                  className="home-image avatar"
                />
                <img
                  alt="image"
                  src="https://images.unsplash.com/photo-1610276198568-eb6d0ff53e48?ixid=Mnw5MTMyMXwwfDF8c2VhcmNofDF8fHBvdHJhaXR8ZW58MHx8fHwxNjY3MjQ0ODcx&amp;ixlib=rb-4.0.3&amp;w=200"
                  className="home-image01 avatar"
                />
                <img
                  alt="image"
                  src="https://images.unsplash.com/photo-1618151313441-bc79b11e5090?ixid=Mnw5MTMyMXwwfDF8c2VhcmNofDEzfHxwb3RyYWl0fGVufDB8fHx8MTY2NzI0NDg3MQ&amp;ixlib=rb-4.0.3&amp;w=200"
                  className="home-image02 avatar"
                />
              </div>
              <label className="home-caption1">
                Loved by 100,000+ people like you.
              </label>
            </div>
          </div>
          <div className="home-image03">
            <img
              alt="image"
              src="/playground_assets/hero-image.png"
              className="home-image04"
            />
          </div>
        </div>
      </section>
      <section id="features" className="home-section1">
        <header className="home-header1">
          <h2 className="home-text02">Features</h2>
        </header>
        <section className="home-note">
          <div className="home-image07">
            <img
              alt="image"
              src="/playground_assets/home-convert-img.png"
              className="home-image08"
            />
          </div>
          <div className="home-content1">
            <div className="home-main1">
              <div className="home-caption2"></div>
              <div className="home-heading01">
                <h2 className="section-heading gradient-text">
                  Convert and Install Android App Bundles
                </h2>
                <p className="section-description">
                  Effortlessly convert and install Android App Bundles (.aab)
                  files with our unique solution. No need to search the market;
                  we've got you covered.
                </p>
              </div>
            </div>
            <div
              className="home-get-started button gradient-bg"
              onClick={scrollToDownload}
            >
              <span className="home-text03">Get started</span>
            </div>
          </div>
        </section>
        <section className="home-note1">
          <div className="home-image09">
            <img
              alt="image"
              src="/playground_assets/home-file-support-img.png"
              className="home-image10"
            />
          </div>
          <div className="home-content2">
            <main className="home-main2">
              <header className="home-caption3"></header>
              <main className="home-heading03">
                <header className="home-header2">
                  <h2 className="section-heading gradient-text">
                    Extensive File Format Support
                  </h2>
                  <p className="section-description">
                    Whether it's .apks, .apk, .apkm, or .xapk files, our App
                    Bundle Installer is equipped to handle all your conversion
                    and installation needs. One app for all your file format
                    requirements.
                  </p>
                </header>
              </main>
            </main>
            <div
              className="home-get-started button gradient-bg"
              onClick={scrollToDownload}
            >
              <span className="home-text04">Get started</span>
            </div>
          </div>
        </section>
        <section className="home-note2">
          <div className="home-image11">
            <img
              alt="image"
              src="/playground_assets/home-file-org-img.png"
              className="home-image12"
            />
          </div>
          <div className="home-content3">
            <div className="home-main3">
              <div className="home-caption4"></div>
              <div className="home-heading05">
                <h2 className="section-heading gradient-text">
                  Scan, Sort, and Manage Your App Files
                </h2>
                <p className="section-description">
                  App Bundle Installer's powerful scanning feature detects .aab,
                  .apks, .apk, .apkm, and .xapk files on your system.
                  Effortlessly sort and manage your files based on size, name,
                  or date for a clutter-free experience.
                </p>
              </div>
            </div>
            <div
              className="home-get-started button gradient-bg"
              onClick={scrollToDownload}
            >
              <span className="home-text05">Get started</span>
            </div>
          </div>
        </section>
        <section className="home-note1">
          <div className="home-image09">
            <img
              alt="image"
              src="/playground_assets/home-dark-chat-img.png"
              className="home-image10"
            />
          </div>
          <div className="home-content2">
            <main className="home-main2">
              <header className="home-caption3"></header>
              <main className="home-heading03">
                <header className="home-header2">
                  <h2 className="section-heading gradient-text">
                    Dark Mode and Chat Support
                  </h2>
                  <p className="section-description">
                    Easily switch to Dark Mode for a comfortable viewing
                    experience and get instant support via Chat Support.
                  </p>
                </header>
              </main>
            </main>
            <div
              className="home-get-started button gradient-bg"
              onClick={scrollToDownload}
            >
              <span className="home-text04">Get started</span>
            </div>
          </div>
        </section>
      </section>
      <section id="why-choose" className="home-section2">
        <header className="home-header3">
          <header className="home-left">
            <h2 className="section-heading gradient-text">
              <span>Why Choose App Bundle Installer</span>
              <br></br>
            </h2>
          </header>
          <div className="home-right"></div>
        </header>
        <main className="home-cards">
          <Card rootClassName="card-root-class-name"></Card>
          <Card
            Icon="/playground_assets/easy.png"
            Header="Easy to use"
            Description="Our app has a user-friendly interface that makes it easy to install apps, even for those who are new to technology"
            rootClassName="card-root-class-name1"
          ></Card>
          <Card
            Icon="/playground_assets/fast.png"
            Header="Fast and efficient"
            Description="Our conversion process is quick and efficient, so you can start using your favorite apps in no time."
            rootClassName="card-root-class-name2"
          ></Card>
        </main>
      </section>
      <section className="home-section3"></section>
      <section id="download" className="home-section4">
        <main className="home-content4">
          <header className="home-header4">
            <h2 className="home-heading08 section-heading">
              Get started with App Bundle Installer today!
            </h2>
            <div className="home-buttons1">
              <div
                className="home-android button"
                onClick={handleDownloadClick}
              >
                <img
                  alt="image"
                  src="/playground_assets/fast.png"
                  className="home-icon"
                />
                <span className="home-text08 gradient-bg-sec">
                  {buttonText}
                </span>
              </div>
            </div>
          </header>
        </main>
      </section>
      <footer id="contact" className="home-footer">
        <div className="home-content5">
          <main className="home-main-content">
            <div className="home-content6">
              <header className="home-main4">
                <div className="home-header5">
                  <img
                    alt="image"
                    src="/playground_assets/brand-logo.svg"
                    className="home-branding"
                  />
                  <span className="home-text09">
                    Simplify your app conversions with App Bundle Installer
                  </span>
                </div>
                <div className="home-socials">
                  <Tippy content="lifesavi@gmail.com">
                    <img
                      className="email-icon social-icon"
                      src="/playground_assets/email-200h.png"
                      alt="Email Icon"
                      onClick={handleEmailClick}
                    />
                  </Tippy>
                  <Tippy content="@savihealer">
                    <img
                      className="telegram-icon social-icon"
                      src="/playground_assets/telegram-200h.png"
                      alt="Telegram Icon"
                      onClick={handleTelegramClick}
                    />
                  </Tippy>
                </div>
              </header>
              <header className="home-categories"></header>
            </div>
            <section className="home-copyright">
              <span className="home-text23">
                © 2023 App Bundle Installer. All Rights Reserved.
              </span>
            </section>
          </main>
          <section className="home-copyright1">
            <span className="home-text28">
              © 2023 App Bundle Installer. All Rights Reserved.
            </span>
          </section>
        </div>
      </footer>
      <div>
        <DangerousHTML
          html={`<script>
    /*
Accordion - Code Embed
*/

/* listenForUrlChangesAccordionx() makes sure that if you changes pages inside your app,
the Accordions will still work*/


window.listenForUrlChangesAccordion = () => {
      let url = location.href;
      document.body.addEventListener(
        "click",
        () => {
          requestAnimationFrame(() => {
            if (url !== location.href) {
              runAccordionCodeEmbed();
              url = location.href;
            }
          });
        },
        true
      );
    };

  
    window.runAccordionCodeEmbed = () => {
    const accordionContainers = document.querySelectorAll('[data-role="accordion-container"]'); // All accordion containers
    const accordionContents = document.querySelectorAll('[data-role="accordion-content"]'); // All accordion content
    const accordionIcons = document.querySelectorAll('[data-role="accordion-icon"]'); // All accordion icons

    accordionContents.forEach((accordionContent) => {
        accordionContent.style.display = "none"; //Hides all accordion contents
    });

    accordionContainers.forEach((accordionContainer, index) => {
        accordionContainer.addEventListener("click", () => {
            accordionContents.forEach((accordionContent) => {
            accordionContent.style.display = "none"; //Hides all accordion contents
            });

            accordionIcons.forEach((accordionIcon) => {
                accordionIcon.style.transform = "rotate(0deg)"; // Resets all icon transforms to 0deg (default)
            });

            accordionContents[index].style.display = "flex"; // Shows accordion content
            accordionIcons[index].style.transform = "rotate(180deg)"; // Rotates accordion icon 180deg
        });
    });
}

runAccordionCodeEmbed()
listenForUrlChangesAccordion()

/*
Here's what the above is doing:
    1. Selects all accordion containers, contents, and icons
    2. Hides all accordion contents
    3. Adds an event listener to each accordion container
    4. When an accordion container is clicked, it:
        - Hides all accordion contents
        - Resets all icon transforms to 0deg (default)
        - Checks if this container has class "accordion-open"
            - If it does, it removes class "accordion-open"
            - If it doesn't, it:
                - Removes class "accordion-open" from all containers
                - Adds class "accordion-open" to this container
                - Shows accordion content
                - Rotates accordion icon 180deg
*/
</script>`}
        ></DangerousHTML>
      </div>
    </div>
  );
};

export default Home;
