const dev = {
  apiUrl: "http://localhost:8080",
};

const prod = {
  apiUrl: "http://21jun6pm-env.eba-fvrw48gc.ap-south-1.elasticbeanstalk.com",
};

const config = process.env.NODE_ENV === "production" ? prod : dev;

export default config;
