import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter as Router, Route } from "react-router-dom";
import { QueryClient, QueryClientProvider } from "react-query";
import ReactGA from "react-ga";

import "./style.css";
import Convert from "./views/convert";
import Home from "./views/home";

const GA_MEASUREMENT_ID = "G-W0NVXR17H1"; // Replace with your Measurement ID
ReactGA.initialize(GA_MEASUREMENT_ID);

const queryClient = new QueryClient();

const App = () => {
  return (
    <QueryClientProvider client={queryClient}>
      <Router>
        <div>
          <Route component={Convert} exact path="/convert" />
          <Route component={Home} exact path="/" />
        </div>
      </Router>
    </QueryClientProvider>
  );
};

ReactDOM.render(<App />, document.getElementById("app"));
