// Convert.js
import React from "react";
import { Helmet } from "react-helmet";
import Converter from "../components/converter";
import Navbar from "../components/navbar";
import "./convert.css";

const Convert = (props) => {
  return (
    <div className="convert-container">
      <Helmet>
        <title>Convert .aab file</title>
      </Helmet>
      <Navbar
        rootClassName="navbar-root-class-name1"
        showMenuItems="false"
      ></Navbar>
      <div className="text-holder">
        <h1 className="section-heading gradient-text text-large">
          Convert your
        </h1>
        <h1 className="section-heading text-large">.aab file easy</h1>
        <span className="convert-text3">
          Convert .AAB (Android App Bundle format) to .APK (Android Package)
          online for free
        </span>
      </div>

      <Converter />
      <div className="convert-container-last"></div>
    </div>
  );
};

export default Convert;
