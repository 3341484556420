import React, {
  useState,
  useEffect,
  useRef,
  useCallback,
  useMuseemo,
} from "react";
import { useHistory } from "react-router-dom";
import { useDropzone } from "react-dropzone";
import { useMutation } from "react-query";
import { getExtension, uploadFile, cancelConversion } from "../helpers/helper";
import "./converter.css";
import { jwthelper } from "../helpers/jwthelper"; // Import custom useInterval hook
import { v4 as uuidv4 } from "uuid";
import ReactGA from "react-ga";

const getEstimatedTime = (fileSize) => {
  var size = fileSize > 20 ? 1 : 2;
  return Math.ceil((fileSize / 1024 / 1024) * size);
};

const useCountdownTimer = ({ selectedFile, buttonState }) => {
  const [countdownTime, setCountdownTime] = useState(null);

  useEffect(() => {
    let timer;
    if (buttonState === BUTTON_STATES.PROCESSING) {
      const estimatedTime = getEstimatedTime(selectedFile.size);
      setCountdownTime(estimatedTime);

      timer = setInterval(() => {
        setCountdownTime((time) => {
          if (time - 1 <= 0) {
            clearInterval(timer);
            return "We are almost there. It is taking a bit longer";
          }
          return time - 1;
        });
      }, 1000);
    } else {
      setCountdownTime(null);
    }

    return () => {
      if (timer) {
        clearInterval(timer);
      }
    };
  }, [buttonState, selectedFile]);

  return countdownTime;
};

const BUTTON_STATES = {
  IDLE: 0,
  SELECTED: 1,
  PROCESSING: 2,
  COMPLETED: 3,
};

const Converter = () => {
  const [state, setState] = useState({
    icon: "file_status",
    buttonText: "Choose File",
    label: "Drag & drop .aab file here",
    fileActionImage: "cancel",
  });

  const history = useHistory();
  const maxSize = 200 * 1024 * 1024;
  const acceptedFileFormats = ".aab";
  const fileInput = useRef();
  const [percentCompleted, setPercentCompleted] = useState(0);
  const [borderRadiusClass, setBorderRadiusClass] = useState("");
  const [selectedFile, setSelectedFile] = useState(null);
  const [buttonState, setButtonState] = useState(BUTTON_STATES.IDLE);
  const [fileName, setFileName] = useState("");
  const [containerClass, setContainerClass] = useState(
    "converter-border-container"
  );
  const [jobId, setJobId] = useState(null);

  const countdownTime = useCountdownTimer({ selectedFile, buttonState });
  const isValidFile = (file) => {
    return getExtension(file.name).toLowerCase() === "aab";
  };

  const MAX_FILE_SIZE_MB = 200;
  const MAX_FILE_SIZE = MAX_FILE_SIZE_MB * 1024 * 1024; // 75 MB in bytes

  const isFileSizeValid = (file) => {
    return file.size <= MAX_FILE_SIZE;
  };

  const checkValidFile = (file) => {
    if (!isValidFile(file)) {
      setState({
        ...state,
        label: "Please choose a .aab file only.",
      });
      return false;
    } else if (!isFileSizeValid(file)) {
      setState({
        ...state,
        label: `File size should be less than ${MAX_FILE_SIZE_MB}MB.`,
      });
      return false;
    }
    return true;
  };

  const getButtonText = () => {
    if (buttonState === BUTTON_STATES.PROCESSING) {
      if (typeof countdownTime === "number") {
        return `Processing... (${countdownTime}s)`;
      } else {
        return `Processing... (Almost there)`;
      }
    } else if (buttonState === BUTTON_STATES.COMPLETED) {
      return "Download APK";
    }
    return state.buttonText;
  };

  const onDrop = useCallback(
    (acceptedFiles) => {
      console.log("onDrop: " + acceptedFiles[0]);
      ReactGA.event({
        category: "File Selection",
        action: "Drag and Drop File",
      });
      if (checkValidFile(acceptedFiles[0])) {
        setJobId(uuidv4());
        setState({
          ...state,
          label: "Please wait while we convert .AAB to .APK",
        });
        setSelectedFile(acceptedFiles[0]);
        setFileName(acceptedFiles[0].name);
        setButtonState(BUTTON_STATES.SELECTED);
      }
    },
    [state]
  );

  const handleFileInputChange = (e) => {
    e.preventDefault();
    console.log("handleFileInputChange: " + e.target.files[0]);
    if (checkValidFile(e.target.files[0])) {
      onDrop(e.target.files);
    }
  };

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop: (files) => {
      if (buttonState === BUTTON_STATES.IDLE) {
        onDrop(files);
      }
    },
    maxSize,
    accept: acceptedFileFormats,
    noClick: buttonState !== BUTTON_STATES.IDLE,
  });

  const mutation = useMutation(
    ({ file, onProgress }) => uploadFile({ file, onProgress, jobId }),
    {
      onMutate: () => {
        setState({ ...state, buttonText: "Processing..." });
        setButtonState(BUTTON_STATES.PROCESSING);
      },
      onSuccess: (data) => {
        setJobId(null);
        if (data.exception && data.exception.status === 106) {
          // Do nothing
        }
        if (jobId === data.jobId) {
          setButtonState(BUTTON_STATES.COMPLETED);
          setState({
            ...state,
            label: "Conversion successful! Drag & drop a new file to convert.",
            buttonText: "Download APK",
            icon: "success_status",
            fileActionImage: "done",
          });
          setFileName("");
        } else {
          console.log("Incorrect jon id");
        }
        console.log(data);
      },
      onError: (error) => {
        console.log(error);
        setJobId(null);
        setState({
          ...state,
          label:
            "An error occurred while converting the file. Please try again later.",
          buttonText: "Choose File",
          icon: "file_status",
          fileActionImage: "cancel",
        });
        setButtonState(BUTTON_STATES.IDLE);
        setFileName("");
      },
    }
  );

  useEffect(() => {
    ReactGA.pageview("/convert");
  }, []);

  useEffect(() => {
    console.log(
      `useEffect isButtonState` + buttonState === BUTTON_STATES.COMPLETED
    );
    if (selectedFile && buttonState === BUTTON_STATES.SELECTED) {
      setState({ ...state, icon: "upload_status" });

      mutation.mutate({
        file: selectedFile,
        onProgress: (percentCompleted) => {
          setState({
            ...state,
            buttonText: `Uploading... ${percentCompleted}%`,
          });
          setPercentCompleted(percentCompleted);

          if (percentCompleted === 100) {
            setState({
              ...state,
              buttonText: "Converting...",
              icon: "convert_status",
            });
            setBorderRadiusClass("half-border-radius");
            updateContainerClass("static-green-border");
          } else if (percentCompleted > 1 && percentCompleted < 100) {
            updateContainerClass("animated-border");
          }
        },
      });
    }
  }, [selectedFile, buttonState]);

  useEffect(() => {
    const handleBeforeUnload = async (e) => {
      if (jobId) {
        e.preventDefault();
        try {
          print("Inside useEffect cancel jobId: " + jobId);
          await cancelConversion(jobId);
        } catch (error) {
          console.error("Error while canceling conversion on unload:", error);
        }
      }
    };

    window.addEventListener("beforeunload", handleBeforeUnload);
    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, [jobId]);

  const updateContainerClass = (additionalClass) => {
    setContainerClass(`converter-border-container ${additionalClass}`);
  };

  const getFileNameFromURL = () => {
    return "Your APK is ready for download!";
  };

  const handleDownloadButtonClick = useCallback(() => {
    ReactGA.event({
      category: "Button Click",
      action: "Download APK",
    });
    if (
      buttonState === BUTTON_STATES.COMPLETED ||
      (buttonState === BUTTON_STATES.PROCESSING && mutation.isSuccess)
    ) {
      const link = document.createElement("a");
      link.href = mutation.data.fileUrl;
      link.target = "_blank";
      link.download = "converted.apk";
      link.click();
    } else if (buttonState === BUTTON_STATES.IDLE) {
      fileInput.current.click();
    }
  }, [buttonState, mutation.data, state]);

  const handleCancelClick = async (jobId) => {
    ReactGA.event({
      category: "Button Click",
      action: "Cancel Conversion",
    });
    try {
      await cancelConversion(jobId);
      resetState();
    } catch (error) {
      console.error("Error while canceling conversion:", error);
      resetState();
    }
  };

  useEffect(() => {
    const unlisten = history.listen((location) => {
      if (jobId) {
        handleCancelClick(jobId);
        mutation.reset();
      }
    });

    return () => {
      unlisten();
    };
  }, [history, jobId, handleCancelClick, mutation]);

  const resetState = () => {
    setState({
      ...state,
      buttonText: "Choose File",
      icon: "file_status",
      label: "Drag & drop .aab file here",
      fileActionImage: "cancel",
    });
    setButtonState(BUTTON_STATES.IDLE);
    setFileName("");
    setPercentCompleted(0);
    setBorderRadiusClass("");
    updateContainerClass("converter-border-container");
    setJobId(null);
  };

  return (
    <div className="converter-parent-container">
      <div className={containerClass}>
        <div className="converter-component-container" {...getRootProps()}>
          <input {...getInputProps()} />
          <img
            className={`icon ${
              buttonState === BUTTON_STATES.PROCESSING ? "icon-rotate" : ""
            }`}
            src={`/playground_assets/${state.icon}.png`}
          />
          <span className="status-text">
            {isDragActive ? "Drop the file here..." : state.label}
          </span>
          <div className="convert-get-started button">
            <label
              htmlFor="fileInput"
              className="button-text"
              onClick={handleDownloadButtonClick}
            >
              {getButtonText()}
              <input
                ref={fileInput}
                type="file"
                id="fileInput"
                style={{ display: "none" }}
                accept={acceptedFileFormats}
                onChange={handleFileInputChange}
              />
            </label>
          </div>
        </div>
        {buttonState !== BUTTON_STATES.IDLE && (
          <div className="file-info-container">
            <div
              className={"file-progress " + borderRadiusClass}
              style={{ width: `${percentCompleted}%` }}
            />
            <div className="file-info">
              <span className="caption-text" style={{ color: "white" }}>
                {buttonState === BUTTON_STATES.COMPLETED && mutation.data
                  ? getFileNameFromURL()
                  : fileName
                  ? `${fileName} (${Math.round(
                      selectedFile.size / 1024 / 1024
                    )} MB)`
                  : null}
              </span>
              <img
                className="file-action-img"
                src={`/playground_assets/${state.fileActionImage}.png`}
                onClick={() => {
                  if (buttonState === BUTTON_STATES.COMPLETED) return;
                  if (
                    window.confirm(
                      "Do you want to cancel this operation? The selected file will not be uploaded."
                    )
                  ) {
                    handleCancelClick(jobId);
                    mutation.reset();
                  }
                }}
              />
            </div>
          </div>
        )}
      </div>
      {buttonState === BUTTON_STATES.COMPLETED && (
        <div
          className="convert-another-file"
          onClick={() => {
            ReactGA.event({
              category: "Button Click",
              action: "Convert Another File",
            });
            resetState();
          }}
        >
          Convert another file
        </div>
      )}
    </div>
  );
};

export default Converter;
