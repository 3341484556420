import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";

import DangerousHTML from "dangerous-html/react";
import PropTypes from "prop-types";

import "./navbar.css";

const Navbar = (props) => {
  const scrollToSection = (id) => {
    const element = document.getElementById(id);
    if (element) {
      element.scrollIntoView({ behavior: "smooth", block: "start" });
      setTimeout(() => {
        const closeButton = document.querySelector("#close-mobile-menu");
        closeButton && closeButton.click();
      }, 50);
    }
  };

  // Debounce function
  const debounce = (func, wait) => {
    let timeout;
    return (...args) => {
      clearTimeout(timeout);
      timeout = setTimeout(() => func.apply(this, args), wait);
    };
  };

  useEffect(() => {
    const handleResize = debounce(() => {
      const mobileMenu = document.getElementById("mobile-menu");
      mobileMenu.style.transform = "translateX(120%)";
    }, 200);

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  console.log("Navigation prop " + props.showMenuItems);
  return (
    <nav
      className={`navbar-navbar ${
        props.showMenuItems === "true" ? "navbar-fullwidth" : ""
      } ${props.rootClassName}`}
    >
      <Link to="/">
        <img
          alt="Planical7012"
          src={props.BrandingLogo}
          className="navbar-branding-logo"
        />
      </Link>
      <div className="navbar-nav-content">
        {props.showMenuItems === "true" && (
          <div className="navbar-nav-links">
            <span
              className="navbar-link nav-link"
              onClick={() => scrollToSection("features")}
            >
              Features
            </span>
            <span
              className="nav-link"
              onClick={() => scrollToSection("download")}
            >
              Download
            </span>
            <span
              className="nav-link"
              onClick={() => scrollToSection("contact")}
            >
              Contact
            </span>
            {/* <Link to="/convert">
              <div className="get-started navbar-get-started">
                <span className="navbar-text ">Convert Online (Beta)</span>
              </div>
            </Link> */}
          </div>
        )}

        {props.showMenuItems === "true" && (
          <div id="open-mobile-menu" className="navbar-hamburger get-started">
            <img
              alt={props.image_alt}
              src={props.image_src}
              className="navbar-image"
            />
          </div>
        )}
      </div>
      <div id="mobile-menu" className="navbar-mobile-menu">
        <div className="navbar-branding">
          <img className="navbar-image1" />
          <button id="close-mobile-menu" className="navbar-container">
            Close
          </button>
        </div>
        <div className="navbar-nav-links1">
          <span
            className="nav-link"
            onClick={() => scrollToSection("features")}
          >
            Features
          </span>
          <span
            className="nav-link"
            onClick={() => scrollToSection("why-choose")}
          >
            Why choose
          </span>
          {/* <Link
            className="nav-link"
            onClick={() => scrollToSection("convert-online")}
            to="/convert"
          >
            Convert Online (Beta)
          </Link> */}
          <span className="nav-link" onClick={() => scrollToSection("contact")}>
            Contact
          </span>
        </div>
        <div
          className="get-started"
          onClick={() => scrollToSection("download")}
        >
          <span className="navbar-text1">Download APK</span>
        </div>
      </div>
      <div className="">
        <DangerousHTML
          html={`
    <script>
      if (typeof window !== "undefined") {
        const runMobileMenuCodeEmbed = () => {
          // Mobile menu
          const mobileMenu = document.querySelector("#mobile-menu")

          // Buttons
          const closeButton = document.querySelector("#close-mobile-menu")
          const openButton = document.querySelector("#open-mobile-menu")

          // On openButton click, set the mobileMenu position left to -100vw
          openButton && openButton.addEventListener("click", function() {
            mobileMenu.style.transform = "translateX(0%)"
          })

          // On closeButton click, set the mobileMenu position to 0vw
          closeButton.addEventListener('click', function() {
            const mobileMenu = document.getElementById('mobile-menu');
            mobileMenu.style.transform = "translateX(120%)"
          });

        }

        runMobileMenuCodeEmbed()
      }
    </script>
  `}
          className=""
        ></DangerousHTML>
      </div>
    </nav>
  );
};

Navbar.defaultProps = {
  image_alt: "image",
  image_src1: " ",
  image_src: "/playground_assets/hamburger-200h.png",
  image_alt1: "image",
  rootClassName: "",
  BrandingLogo: "/playground_assets/brand-logo.svg",
};

Navbar.propTypes = {
  image_alt: PropTypes.string,
  image_src1: PropTypes.string,
  image_src: PropTypes.string,
  image_alt1: PropTypes.string,
  rootClassName: PropTypes.string,
  BrandingLogo: PropTypes.string,
};

export default Navbar;
