import { KJUR } from "jsrsasign";

const store = "5d9f9594053f99b721d648616f5bfd192cb96a28T";
const package_aab = "com.lifesavi.bundle";
const username = "lifesavi@gmail.com";
const password = "%%aab@android%%";
const key =
  "sX2s9YX8Rbq6t5nLKw9q8a#U?qCSAc7h@*hQBwx2HJcjBc?Yc&A%zvm8c&pUem-jaUKKf_^4&26vhc2acN4?zM@6vT$&rD87sSgJX%QnJHDMNSW#Vb%v+vzWbyS";

const algorithm = "HS256";

const generateToken = () => {
  const startTime = Math.floor(Date.now() / 1000);
  const endTime = startTime + 5 * 60; // 5 minutes after start time
  const header = {
    alg: algorithm,
    typ: "JWT",
  };

  const payload = {
    aud: "PUBLIC",
    iss: package_aab,
    iat: startTime,
    exp: endTime,
  };

  const secret = username + password + key + store;

  const token = KJUR.jws.JWS.sign(null, header, payload, { utf8: secret });

  return token;
};

export { generateToken };
