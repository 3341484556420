// helper.js
import axios from "axios";
import { v4 as uuidv4 } from "uuid";
import config from "./conf";
import { generateToken } from "./jwthelper";

export const getExtension = (filename) => {
  return filename.split(".").pop();
};

export const uploadFile = async ({ file, onProgress, jobId }) => {
  const formData = new FormData();
  formData.append("file", file);
  const token = generateToken();
  formData.append("token", token);
  console.log("Generated token: " + token);

  const axiosConfig = {
    onUploadProgress: (progressEvent) => {
      const percentCompleted = Math.round(
        (progressEvent.loaded * 100) / progressEvent.total
      );
      if (onProgress) {
        onProgress(percentCompleted);
      }
    },
    headers: {
      "Content-Type": "multipart/form-data",
    },
  };

  try {
    const response = await axios.post(
      `${config.apiUrl}/convertAabToApkUrlFamilyV2/${jobId}`,
      formData,
      axiosConfig
    );
    return response.data;
  } catch (error) {
    console.error("Error uploading file:", error);
    throw error;
  }
};

export const cancelConversion = async (jobId) => {
  try {
    const response = await axios.post(
      `${config.apiUrl}/cancelAabToApkConversion/${jobId}`
    );
    return response.data;
  } catch (error) {
    console.error("Error cancelling conversion:", error);
    throw error;
  }
};

export const getDownloadUrl = async () => {
  try {
    const token = generateToken();
    const response = await axios.get(`${config.apiUrl}/download-apk`, {
      headers: { Authorization: `Bearer ${token}` },
    });
    return response.data.downloadUrl;
  } catch (error) {
    console.error("Error getting download URL:", error);
    throw error;
  }
};
